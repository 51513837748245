const photo = () => (
  <figure>
    <img src="taylor-hernandez-cm_PpUQU-lk-unsplash.jpg" alt="" />
    <figcaption>
      <a href="https://unsplash.com/@taylormae?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
        Taylor Hernandez
      </a>
    </figcaption>
  </figure>
);

export const ShortTherapy = ({ language }) => {
  if (language === "en") {
    return (
      <>
        <h2>Short-term therapy</h2>
        <p>
          <em>Anxious? In the middle of a crisis? Lost a loved one?</em>
        </p>
        <div>
          <p>
            Short-term therapy <strong>supports your wellbeing</strong>. For
            example anxiety, exhaustion or loneliness are good reasons to seek
            short-term therapy. It can also be preventative. Therapy is always
            confidential.
          </p>
          <p>
            Therapy is offered in 45&mdash;90 minute sessions. Maximum duration
            is 20 sessions. Even a single session can be extremely helpful!
          </p>
        </div>
        <a className="cta" href="mailto:helena@intervuo.fi">
          Click to contact me!
        </a>
        {photo()}
      </>
    );
  }

  return (
    <>
      <h2>Lyhytterapia</h2>
      <p>
        <em>Ahdistaako? Iskeekö ikäkriisi? Menetitkö läheisen?</em>
      </p>
      <div>
        <p>
          Lyhytterapiassa <strong>tuetaan hyvinvointia</strong>. Esimerkiksi
          elämänkriisit, ahdistuneisuus, uupumusoireet tai yksinäisyys ovat
          hyviä syitä hakeutua lyhytterapiaan. Terapia voi olla myös
          ennaltaehkäisevää. Keskustelut ovat luottamuksellista.
        </p>
        <p>
          Terapiatapaamiset 45&mdash;90 minuuttia, enimmäismäärä 20 tapaamista.
          Jo yksikin kerta voi auttaa merkittävästi!
        </p>
      </div>
      <a className="cta" href="mailto:helena@intervuo.fi">
        Ota yhteyttä tästä!
      </a>
      {photo()}
    </>
  );
};
