export const Heading = ({ language }) => {
  if (language === "en") {
    return (
      <>
        <h1>Intervuo</h1>
        <em>Work counceling, coaching, therapy</em>
      </>
    );
  }

  return (
    <>
      <h1>Intervuo</h1>
      <em>
        Esihenkilöiden, asiantuntijoiden ja organisaatioiden hyvän työelämän
        tueksi
      </em>
    </>
  );
};
