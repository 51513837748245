const photo = () => (
  <figure>
    <img src="jason-goodman-bzqU01v-G54-unsplash.jpg" alt="" />
    <figcaption>
      <a href="https://unsplash.com/@jasongoodman_youxventures?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
        Jason Goodman
      </a>
    </figcaption>
  </figure>
);

export const WorkCounseling = ({ language }) => {
  if (language === "en") {
    return (
      <>
        <h2>Work counseling</h2>
        <p>
          <em>
            Is it difficult to get up in the morning and to start working? Do
            you lack motivation at work? Do you feel like there's nothing new to
            learn? Does your team need support for improving collaboration?
          </em>
        </p>
        <div>
          <ul>
            <li>Work counseling is for teams or individuals</li>
            <li>
              We focus on developing your working methods and finding new
              perspectives
            </li>
            <li>
              Let's have confidential conversations around your{" "}
              <strong>questions, experiences, and emotions</strong> related to
              your working environment
            </li>
          </ul>
        </div>
        {photo()}
        <h3>Examples of work counceling packages</h3>
        <ul>
          <li>
            A five person software developer team wants to improve workplace
            wellbeing and ensure excellence in customer-facing communication
            skills. Three 90 minute meetings, in one month intervals.
          </li>
          <li>
            Re-organization of a midsized company has significant effects on the
            personnel. Continuous facilitation of leadership and management in
            situations of change for the whole personnel, in small groups of
            max. 10 people each. Monthly 90-minute sessions per team.
          </li>
        </ul>
        <a className="cta" href="mailto:helena@intervuo.fi">
          Click to contact me!
        </a>
      </>
    );
  }
  return (
    <>
      <h2>Työnohjaus</h2>
      <p>
        <em>
          Onko työyhteisössä kehittämisen tarvetta? Onko aamulla vaikeuksia
          motivoida itseä aloittamaan työt? Onko työn imu tai motivaatio
          hukassa? Tuntuuko siltä, ettei työstä löydä enää mitään uutta?
          Työnohjaus tapahtuu joko yksilö- tai ryhmätapaamisina.
        </em>
      </p>
      {photo()}
      <h3>Esimerkkejä työnohjauspaketeista</h3>
      <ul>
        <li>
          <strong>Johtamisen tueksi</strong>: Työhyvinvoinnin, itsensä
          johtamisen ja vuorovaikutus- ja kommunikaatiotaitojen kehittäminen
          työyhteisössä; neljä 90 minuutin tapaamista kuukauden välein tai
          tarpeen mukaan.
        </li>
        <li>
          <strong>Muutostilanteissa</strong>: Organisaatiouudistuksen ja
          yritysstrategian läpivienti ulkopuolisen työnohjaajan turvin, enintään
          10 hengen ryhmät, neljä kertaa 90 minuutin fasilitoituja työpajoja ,
          tarvittavan ajanjakson mukaan.
        </li>
        <li>
          <strong>Huono työilmapiiri tai korkea vaihtuvuus</strong>:
          Organisaation tai työyhteisön sisäiset konfliktit, niiden selvittely
          ja hyvän työyhteisön jälleenrakennus; 3-5 työnohjauksellista työpajaa
          yksilöllisen aikataulun mukaan.
        </li>
      </ul>
      <a className="cta" href="mailto:helena@intervuo.fi">
        Ota yhteyttä tästä!
      </a>
      <blockquote>
        <strong>
          &ldquo;Vuosia jatkunut yhteistyö on ollut sujuvaa ja mielekästä.
          Työntekijöiltä saadun palautteen mukaan työnohjauksen laatuun ollaan
          keskimäärin erittäin tyytyväisiä. Laaja-alainen koulutus- ja työtausta
          yhdistettynä työnohjauksen käytännön kokemukseen on havaittu kykynä
          mukautua erilaisiin tilanteisiin/tarpeisiin.&rdquo;
        </strong>
        <br />
        Pk-yrityksen toimitusjohtaja
      </blockquote>
    </>
  );
};
