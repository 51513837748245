const photo = () => (
  <figure>
    <img src="randy-tarampi-U2eUlPEKIgU-unsplash.jpg" alt="" />
    <figcaption>
      <a href="https://unsplash.com/@randytarampi?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
        Randy Tarampi
      </a>
    </figcaption>
  </figure>
);

export const Coaching = ({ language }) => {
  if (language === "en") {
    return (
      <>
        <h2>Coaching</h2>
        <p>
          <em>
            Are you happy with your job and how you can express yourself in it?
            Do you have unused potential? Do you want to accomplish something
            new?
          </em>
        </p>
        <p>
          <em>
            Do you want to ensure success and feel empowered even in difficult
            situations? Does your team want to get a boost in productivity and
            increase wellbeing at work?
          </em>
        </p>
        <div>
          <ul>
            <li>
              Coaching is focused around a specified <strong>goal</strong> of
              the individual or team
            </li>
            <li>
              The goal can be professional or personal. We will work towards it
              with solution-focused methodology
            </li>
            <li>
              During the coaching process we will find and utilize your existing
              strenghts to ensure success
            </li>
          </ul>
        </div>
        <p>
          Let's chat more about the possibilities to lift yourself and your team
          to the next level. My services are applicable to every field of work.
        </p>
        <h3>Examples of coaching packages</h3>
        <ul>
          <li>
            Do you want to attain a new degree alongside your day job? Let's
            craft a personalized plan for combining these two demanding
            projects, while keeping up personal wellbeing and the pace for
            graduating. For individuals, 90 minutes per meeting.
          </li>
          <li>
            The work community is stuck with old habits and wants to invent
            something new. Let's figure out new refreshing processes and dust up
            the organization's working methods. Custom-made personalized
            workshop for companies. 3 hours, max. 20 people.
          </li>
          <li>
            Customer complaints improvisation workshop: plan and practice
            possible difficult customer situations. Learn how to manage
            customer's emotional outbursts and feedback in a constructive and
            solution-focused way, via practical exercises. Let's design
            processes and methods together in advance, so that difficult
            situations won't come as a surprise in the future. Group of max 30
            people, 2 hours.
          </li>
          <li>Some coaching sessions are available pro bono</li>
        </ul>
        <a className="cta" href="mailto:helena@intervuo.fi">
          Click to contact me!
        </a>
        {photo()}
      </>
    );
  }
  return (
    <>
      <h2>Valmennus</h2>
      <p>
        <em>
          Haluatko varmistaa onnistumisen työssäsi esihenkilönä tai
          asiantuntijana? Haluatko tuntea itsesi varmaksi myös haastavissa
          tilanteissa ryhmän kanssa? Haluaako työryhmäsi tehostaa toimintaa ja
          lisätä työhyvinvointia?
        </em>
      </p>
      <h3>Esimerkkejä valmennuspaketeista</h3>
      <ul>
        <li>
          <strong>Työyhteisön ongelmia ennaltaehkäisevä toiminta</strong>:
          Työyhteisön virkistyspäivään sopivaa kehittämistä, samalla kun ollaan
          kaikki yhdessä koolla? Yhteishenkeä kehittävät työpajat enintään 20
          henkilölle, kahden tunnin kokonaisuus.
        </li>
        <li>
          <strong>Kiperien tilanteiden selvittelytaidot</strong>: Opetellaan
          tyytymättömän asiakkaan tunteikkaan palautteen ratkaisukeskeistä ja
          lempeää käsittelyä, käytännön harjoittein; reklamaatioteatteri.
          Suunnitellaan toimintamalleja etukäteen, ettei tilanteet tule jatkossa
          yllätyksenä. Enintään 30 henkilön ryhmä, kahden tunnin kokonaisuus.
        </li>
        <li>
          <strong>Itsensä kehittäminen</strong>: Uuden tutkinnon suorittaminen
          työn ohessa? Laaditaan yhdessä yksilöllinen suunnitelma kahden
          vaativan asian yhdistämiseen siten, että hyvinvointi ja tavoitetahti
          pysyy kumpikin yllä. Pikaopastus yksityishenkilölle 90 minuutin
          tapaaminen livenä tai etänä.
        </li>
        <li>
          <strong>Inspiroidu ja kukoista</strong>: Työn tekemiseen pitäisi saada
          uutta, luovaa virtaa ja voimaa? Luovuustyöpajat enintään 30 henkilön
          ryhmä, kahden tunnin kokonaisuus.
        </li>
      </ul>
      <p>
        Keskustellaan lisää mahdollisuuksista nostaa itsesi ja tiimisi
        seuraavalle tasolle. Palveluni soveltuvat kaikille toimialoille. Teen
        vuosittain myös muutamia valmennuksia pro bono.
      </p>
      <a className="cta" href="mailto:helena@intervuo.fi">
        Ota yhteyttä tästä!
      </a>
      {photo()}
    </>
  );
};
