export const Footer = ({ language }) => {
  return (
    <footer>
      <a href="mailto:helena@intervuo.fi">helena@intervuo.fi</a>
      <br />
      <a href="tel:+358408459780">+358 40 84 59 780</a>
      <br />
      <span>Intervuo Oy</span>
      <br />
      <span>
        {language === "fi" && "Y-tunnus"}
        {language === "en" && "Business ID"} 2298359-7
      </span>
    </footer>
  );
};
