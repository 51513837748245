const photo = () => (
  <figure>
    <img src="_DSF7273.jpg" alt="Helena Rila" />
  </figure>
);

export const Intro = ({ language }) => {
  if (language === "en") {
    return (
      <section>
        {photo()}
        <p className="intro">
          In life's questions &mdash; big and small &mdash; it's smart to seek
          advice and assistance. As a professional therapist and work councelor
          I will help you or your team to find your strengths.
        </p>
        <a className="cta" href="mailto:helena@intervuo.fi">
          Click to contact me!
        </a>
      </section>
    );
  }

  return (
    <section>
      {photo()}
      <p className="intro">
        Elämän suurissa ja pienissä kysymyksissä on viisasta hakea tukea ja
        apua. Asiantuntevana terapeuttina ja ratkaisukeskeisenä työnohjaajana
        tuen sinua tai työryhmääsi voimavarakeskeisesti.
      </p>
      <a className="cta" href="mailto:helena@intervuo.fi">
        Ota yhteyttä tästä!
      </a>
      <blockquote>
        <strong>
          &ldquo;Olen ollut Helenan työnohjattavana hänen opiskelujensa aikana.
          Pitkän työuran jo silloin tehneenä koin työssäni väsymystä,
          riittämättömyyttä, epätoivoa, ammattitaidottomuutta, kyllästymistä ja
          mietin alanvaihtoa. Olen kiitollinen, että löysin tuolloin Helenan
          avulla voimavarani ja uskon itseeni uudelleen. Hän vahvisti
          asiantuntijuuttani muistuttamalla työni tuloksista, kysyi kysymyksiä
          joita pohtimalla sain selville miksi kuormitun, väsyn, syyllistyn,
          stressaannun ja myös hyväksymään näitä luonnollisia tunteita.
          Kokemukseni työnohjauksesta oli itselleni todella merkityksellisenä ja
          nykyään opiskelen itsekin työnohjaajaksi. Opiskelujen aikana olen myös
          ymmärtänyt kuinka taitavasti Helena minua tuolloin auttoi.&rdquo;
        </strong>
        <br />
        Arja Ollila, lehtori
      </blockquote>
    </section>
  );
};
