export const Helena = ({ language }) => {
  if (language === "en") {
    return (
      <section>
        <h2>Supporting your journey</h2>
        <h3>Helena Rila</h3>
        <p>
          Extensive experience in work counseling, coaching, and therapy.
          Additional experience in international research and cross-cultural
          cooperation.
        </p>
        <div>
          <ul>
            <li>PhD: child psychiatry</li>
            <li>MA: Licensed speech and language therapist (Valvira)</li>
            <li>MEdPsych: educational psychology</li>
            <li>
              Specialized professional degree: psychiatric care for children and
              youth
            </li>
            <li>Teacher in social and healthcare sciences</li>
            <li>
              Solution focused work counceller (Ratkes Ry & Suomen Työnohjaajat
              Ry)
            </li>
            <li>Solution-focused short-term therapist</li>
          </ul>
        </div>
        <a className="cta" href="mailto:helena@intervuo.fi">
          Click to contact me!
        </a>
      </section>
    );
  }

  return (
    <section>
      <h2>Kanssasi työskentelee</h2>
      <h3>Helena Rila</h3>
      <p>
        Pitkä kokemus työnohjauksesta, valmennuksesta ja terapiasta. Lisäksi
        kokemusta myös kansainvälisestä tutkimuksesta sekä monikulttuurisesta
        yhteistyöstä.
      </p>
      <div>
        <ul>
          <li>Filosofian tohtori: lastenpsykiatria</li>
          <li>Filosofian maisteri: Laillistettu puheterapeutti (Valvira)</li>
          <li>Kasvatustieteiden maisteri: kasvatuspsykologia</li>
          <li>
            Psykiatrisen hoidon erikoisammattitutkinto: lasten- ja
            nuorisopsykiatrinen hoitotyö sekä perhe- ja verkostotyö
          </li>
          <li>Sosiaali- ja terveysalan opettaja</li>
          <li>
            Ratkaisukeskeinen työnohjaaja (Ratkes Ry & Suomen Työnohjaajat Ry)
          </li>
          <li>Ratkaisukeskeinen lyhytterapeutti</li>
        </ul>
      </div>
      <a className="cta" href="mailto:helena@intervuo.fi">
        Ota yhteyttä tästä!
      </a>
    </section>
  );
};
