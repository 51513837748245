import { WorkCounseling } from "./WorkCounseling";
import { Coaching } from "./Coaching";
import { ShortTherapy } from "./ShortTherapy";

export const Services = ({ language }) => {
  if (language === "en") {
    return (
      <section>
        <WorkCounseling language={language} />
        <Coaching language={language} />
        <ShortTherapy language={language} />
      </section>
    );
  }
  return (
    <section>
      <WorkCounseling language={language} />
      <Coaching language={language} />
      <ShortTherapy language={language} />
    </section>
  );
};
