import "./reset.css";
import "./colors.scss";
import "./App.scss";

import { useState } from "react";
import { Heading } from "./Heading";
import { Intro } from "./Intro";
import { Description } from "./Description";
import { Services } from "./Services";
import { Helena } from "./Helena";
import { Footer } from "./Footer";

const App = () => {
  const [language, setLanguage] = useState("fi");

  return (
    <div className="App">
      <header>
        <Heading language={language} />
        <nav id="language">
          <button onClick={() => setLanguage("fi")}>suomeksi</button>
          <button onClick={() => setLanguage("en")}>in english</button>
        </nav>
      </header>
      <main>
        <Intro language={language} />
        <Description language={language} />
        <Services language={language} />
        <Helena language={language} />
      </main>
      <Footer language={language} />
    </div>
  );
};

export default App;
