export const Description = ({ language }) => {
  if (language === "en") {
    return (
      <section>
        <h2>Work counseling, coaching and short-term therapy</h2>
        <div>
          <ul>
            <li>
              In my work counseling and coaching I use a solution-focused method
              based on your strengths. I have given trainings, lectures and
              consultations across Finland and internationally.
            </li>
            <li>
              Coaching and counseling is offered either as individual workshops
              or as regularly occuring group meetings. These are suitable for
              both leadership and management as well as other employees.
            </li>
            <li>
              As a therapist I support individuals, couples and groups of
              individuals in various life events and crises. Anxiety,
              communication problems, and other troubles - you don't have to
              face them alone.
            </li>
          </ul>
        </div>
        <p>
          Below are descriptions of the services I offer and some examples of
          ready-made packages. All services are customizable based on needs and
          situations.
        </p>
      </section>
    );
  }
  return (
    <section>
      <h2>Työnohjausta, valmennusta ja lyhytterapiaa</h2>
      <div>
        <ul>
          <li>
            <strong>Työyhteisöjen muutos- tai konfliktitilanteissa</strong> -
            ratkaisukeskeisellä ja voimavarakeskeisellä työotteella ja
            tulevaisuutta ennakoimalla saat pidettyä tärkeät ihmiset töissä
          </li>
          <li>
            <strong>Kehity esihenkilönä ja asiantuntijana</strong> -
            esihenkilöiden ja johtoryhmien työnohjaus työn kehittämisessä.
          </li>
          <li>
            <strong>
              Kunnioituksen ja arvostuksen kasvattaminen työyhteisön sisällä
            </strong>{" "}
            - organisaation vuorovaikutus- ja kommunikaatiotaitojen parantaminen
            valmennuksien ja työpajojen kautta; ei enää rikkinäisiä puhelimia
          </li>
        </ul>
      </div>
      <p>
        Alla kuvauksia tarjoamistani palveluista sekä esimerkkejä helposti
        ostettavista paketeista. Kaikki palvelut ovat räätälöitävissä tarpeiden
        ja tilanteiden mukaan.
      </p>
      <blockquote>
        <strong>
          &ldquo;Helena tekee työtään suurella sydämellä ja vahvalla
          ammattitaidolla. Hän kykenee ymmärtäväisellä, ratkaisukeskeisellä
          lähestymistavallaan luomaan ilmapiirin, jossa haasteita ja
          onnistumisia on helppo käsitellä. Helenan taito kohdata ihminen
          ihmisenä on omaa luokkaansa!&rdquo;
        </strong>
        <br />
        Työnohjattava
      </blockquote>
    </section>
  );
};
